<template>
<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="8" cy="8" r="8" fill="#E3F6F1"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M11.8111 4.86975C12.063 5.14074 12.063 5.58011 11.8111 5.8511L7.14149 10.8759C6.57485 11.4856 5.65614 11.4856 5.0895 10.8759L3.52221 9.39786C3.27037 9.12686 3.27037 8.6875 3.52221 8.4165C3.77405 8.14551 4.18237 8.14551 4.43421 8.4165L6.0015 9.89452C6.06446 9.96226 6.16653 9.96226 6.22949 9.89452L10.8991 4.86975C11.151 4.59876 11.5593 4.59876 11.8111 4.86975Z" fill="#29A687"/>
</svg>

</template>

<script>
export default {
  name: 'IconCheck'
}
</script>

<style>
</style>
